.lalilala-lines {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}

.lalilala-lines svg {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
}

.lalilala-lines--underline svg {
  bottom: 0;
}

.lalilala-lines--surround svg {
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
